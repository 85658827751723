import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Chip,
  Alert,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  TablePagination,
  Select,
  MenuItem,
  Switch,
  Typography,
  FormControl,
  InputLabel,
  getNativeSelectUtilityClasses,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Modal from "./Modal";
import { useSelector } from "react-redux";
import axios from "axios";
import InfoIcon from '@mui/icons-material/Info';


export default function QuizTwiz(props) {
  const ref = React.useRef();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openUrlD, setOpenUrlD] = useState(false);
  const [clientName, setClientName] = useState("");
  const [gaCode, setGaCode] = useState("");
  const [channelId, setChannelId] = useState("");
  const [clarity, setClarity] = useState("");
  const [company, setCompany] = useState("");
  const [openClarityModal, setOpenClarityModal] = useState(false);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [showCopyAlert, setShowCopyAlert] = React.useState(false);
  const [showSaveAlert, setShowSaveAlert] = React.useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);
  const [showErrorAlert, setShowErrorAlert] = React.useState(false);
  const [showEditAlert, setShowEditAlert] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const [clientVal, setClientVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  var [gagenerated, setGagenerated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [data, setData] = useState();
  const [gaSwitchVal, setGaSwitchVal] = useState(false);
  const [existingClients, setExistingClients] = useState([]);
  const [existingIds, setExistingIds] = useState([]);
  const [existingGas, setExistingGas] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [id, setId] = useState('');
  const [status, setStatus] = useState('');
  const [showDelete, setShowDelete] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');
  const [logsOpen, setLogsOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState([]);
  const [deletedLogs, setDeletedLogs] = useState([]);
  const [deletedLogsOpen, setDeletedLogsOpen] = useState(false);
  const prevClientDomainRef = useRef('');

  //handlelogs
  const handleOpenLogs = async (quizId) => {
    // console.log('quizId', quizId);
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + `apiquiztwiz/getQuizLogs/${quizId}`);

      setLogsOpen(true);
      // console.log('logsData', response);
      setDialogContent(response.data.logsData || []);

    } catch (err) {
      console.error('Error fetching logs data:', err.message);

      setDialogContent([]);
    }
  };
  // handleLogs
  const handleCloseLogs = () => {
    setLogsOpen(false);
  };


  const openForm = () => {
    setDialogOpen(true);
    setCompany("");
    setClientName("");
    setGaCode("");
    setChannelId("");
    setClarity("");
    setIsEditMode(false);
  };
  const openUrl = (client) => {
    setClientVal(client);
    setOpenUrlD(true);
  };
  const userData = useSelector((state) => state.userData);
  // console.log('userData', userData);
  const user = userData.user_name;
  // console.log('user', user);

  const openEditForm = async (_id) => {
    setId(_id);
    const selectedClient = data.find((c) => c._id === _id);
    if (!selectedClient) {
      throw new Error(`Client not found in data.`);
    }
    setCompany(selectedClient.company || '');
    setClientName(selectedClient.client || '');
    setGaCode(selectedClient.ga || '');
    setChannelId(selectedClient.id || '');
    setClarity(selectedClient.clarity || '');
    setStatus(selectedClient.status || 'active');
    setClientVal(selectedClient.client || '');
    prevClientDomainRef.current = selectedClient.client;

    setGaSwitchVal(true);
    setIsEditMode(true);
    setDialogOpen(true);
  };

  const handleDelete = async (delid, client) => {
    // console.log('clientVal', client);
    setLoading(true);
    try {
      const response = await axios.delete(process.env.REACT_APP_API_URL + "apiquiztwiz/deleteQuiz", {
        data: { _id: delid, userId: user, clientName: client },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = response.data;

      await DeleteJsonFields(client);

      if (response.status === 200) {

        setShowDelete(true);
        setTimeout(() => {
          setShowDelete(false);
          setErrMsg("");
        }, 2000);
        setShowDeleteDialog(false);

      } else {

        setErrMsg("Failed to delete quiz", data.message);
        setShowErrorAlert(true);
        setTimeout(() => {
          setShowErrorAlert(false);
        }, 2000);
        return;
      }

      setClientVal("");
      setIdToDelete("");
      await fetchData();
    } catch (error) {
      console.error('Failed to delete quiz: pls try again', error.response.data.message);
      setErrMsg("Failed to delete quiz", error.response.data.message);
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
      return;
    }
    finally {
      setLoading(false);
    }
  };

  const DeleteJsonFields = async (client) => {
    console.log('DeleteJsonFields client', client)
    try {
      const response = await axios.delete(process.env.REACT_APP_API_URL + "apiquiztwiz/deleteJsonField", {
        data: { client: client },
        headers: {
          'Content-Type': 'application/json',
        },
      });
console.log('response',response);
      const data = response.data;
      console.log('Delete data', data)
    } catch (error) {
      setErrMsg("Could not delete fields pls try again", error);
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
      return;
    }
  };

  const showDeletedLogs = async () => {
    try {
      setDeletedLogsOpen(true);
      const response = await axios.get(process.env.REACT_APP_API_URL + `apiquiztwiz/getDeleteLogsByClient`);
      const logsWithFormattedTimestamps = response.data.logs.map(log => ({
        ...log,
        formattedTimestamp: new Date(log.timestamp).toLocaleString()
      }));
      setDeletedLogs(logsWithFormattedTimestamps);
      // console.log('logs', deletedLogs);

    } catch (error) {
      console.error('Error fetching logs:', error);
      setErrMsg('Error fetching Deleted logs. Please try again.', error);
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
      return;
    }
  };

  const handleCloseDeletedLogs = () => {
    // setDeletedLogs([]);
    setDeletedLogsOpen(false);
  }
  // const deactivateUrl = (client, status) => {
  //   console.log('deactivateUrl', client, status)
  //   setShowConfirmationDialog(false);
  //   deactivateClient(client, status);
  //   setShowDeleteAlert(true);
  //   setTimeout(() => {
  //     setShowDeleteAlert(false);
  //     setErrMsg("");
  //   }, 2000);
  // };

  // const handleStatusChange = (clientVal, newStatus) => {
  //   setSelectedStatus(newStatus);
  //   setData((prevClients) =>
  //     prevClients.map((client) => {
  //       if (client.client === clientVal) {
  //         return { ...client, status: newStatus };
  //       }
  //       return client;
  //     })
  //   );
  // };

  // useEffect(() => {
  //   if (isEditMode) {
  //     const originalRecord = data.find((c) => c._id === id);
  //     if (originalRecord) {
  //       setStatus(originalRecord.status);
  //     }
  //   }
  // }, [isEditMode, id, data]);

  // console.log('status..', status)
  const handleStatusChange = (event) => {
    // console.log('status inner', status)
    setStatus(event.target.value);
    // setShowConfirmationDialog(true);

  };

  const closeForm = () => {
    setDialogOpen(false);
    setGaSwitchVal(false);
  };
  // const closeConfirmationDialog = () => {
  //   setShowConfirmationDialog(false);
  // };

  //handleDelete
  const closeDeleteDialog = () => {
    setShowDeleteDialog(false);
  }

  const closeUrlD = () => {
    setOpenUrlD(false);
  };

  const fetchData = async () => {
    let reqOptions = {
      url: process.env.REACT_APP_API_URL + "apiquiztwiz/getQuizDetail",
      method: "GET",
    };
    let response = await axios.request(reqOptions);

    setData(response.data.data.quizData);
    setExistingClients(response.data.data.clients);
    setExistingGas(response.data.data.gas);
    setExistingIds(response.data.data.ids);
  };

  const sendDiscordNotification = async (msg) => {
    let bodyContent = JSON.stringify({
      name: ["devops"],
      content: msg,
    });
    await axios.request({
      url: process.env.REACT_APP_API_URL + "apidiscord/sendDiscordNotification",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: bodyContent,
    });
  };

  const deactivateClient = async (client, status) => {
    try {
      let reqOptions = {
        url: process.env.REACT_APP_API_URL + "apiquiztwiz/deleteQuizDomain",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({ client: client, status: status }),
      };
      let response = await axios.request(reqOptions);

      console.log('deactivated client', response)
      let reqOptions2 = {
        url:
          process.env.REACT_APP_API_URL + "apiquiztwiz/changeStatusQuizDomain",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({ client: client, status: status }),
      };
      let response2 = await axios.request(reqOptions2);
      console.log('response2 change status', response2)
      if (status === "active") {
        sendDiscordNotification("A Domain has been REACTIVATED - " + clientName);
        updateQuizDomain();
      } else {
        sendDiscordNotification("A Domain has been DEACTIVATED - " + clientName);
      }
      await fetchData();
    } catch (e) {
      console.error(e);
      setErrMsg(e);
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const isAlreadyExists = (val, arr) =>
    arr.some((el) => {
      return el === val;
    });

  //call on handleEdit function
  const EditQuizFields = async () => {

    try {
      const response = await axios.patch(
        process.env.REACT_APP_API_URL + "apiquiztwiz/updateQuizFields",
        {
          user: user,
          client: clientName,
          company: company || '',
          ga: gaCode,
          clarity: clarity || '',
          status: status,
          id: channelId,
          _id: id

        }
      );

      console.log('response', response);

    } catch (error) {
      console.error("Error updating quiz details:", error);
    }
  };

  //call on handleEdit function
  // const updateDomain = async () => {

  //   try {
  //     const apiUrl = `${process.env.REACT_APP_API_URL}apiquiztwiz/updateDomainFields`;

  //     const payload = {
  //       oldClient: prevClientDomainRef.current,
  //       newClient: clientName
  //     };

  //     const response = await axios.patch(apiUrl, payload);


  //     // Optionally, you can add some success handling here if needed
  //   } catch (error) {
  //     console.error("Error updating quiz domains:", error);

  //     if (error.response) {
  //       console.error('Error response data:', error.response.data);
  //       console.error('Error response status:', error.response.status);
  //     }

  //     setErrMsg(error.response ? error.response.data.message : "An error occurred");
  //     setShowDeleteAlert(true);

  //     setTimeout(() => {
  //       setShowDeleteAlert(false);
  //       setErrMsg("");
  //     }, 2000);
  //   }
  // };

  const updateQuizDomain = async () => {
    console.log('clientName update domian', clientName)
    try {

      await axios.post(
        process.env.REACT_APP_API_URL + "apiquiztwiz/updateQuizDomain",
        {
          newUrls: [`https://${clientName}.quiztwiz.com`]
        }
      );
      console.log('update the domain')
    } catch (error) {
      console.error("Error updating quiz domains:", error);
      setErrMsg(error);
      setShowDeleteAlert(true);
      setTimeout(() => {
        setShowDeleteAlert(false);
        setErrMsg("");
      }, 2000);
    }
  };

  const updateQuizDetails = async () => {
    console.log('user...', user, clientName, channelId, gaCode)
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + "apiquiztwiz/updateQuizDetail",
        {
          company: company ?? "",
          client: clientName,
          id: channelId,
          ga: gaCode,
          clarity: clarity ?? "",
          status: status ?? "",
          user: user,
        }
      );
    } catch (error) {
      console.error("Error saving client information:", error);
      setErrMsg(error);
      setShowDeleteAlert(true);
      setTimeout(() => {
        setShowDeleteAlert(false);
        setErrMsg("");
      }, 2000);
    }
  };

  const validateDomain = () => {
    let errorMessage = "";

    if (isAlreadyExists(clientName, existingClients)) {
      errorMessage = `Client ${clientName} already exists!`;
    }
    if (!errorMessage && isAlreadyExists(gaCode, existingGas)) {
      errorMessage = `GA code ${gaCode} already assigned to another client!`;
    }
    if (!errorMessage && isAlreadyExists(channelId, existingIds)) {
      errorMessage = `channelId ${channelId} already assigned to another client!`;
    }

    if (errorMessage) {
      console.log("errorMessage", errorMessage);
      setErrMsg(errorMessage);
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 4000);
      return false;
    }
    return true;
  };

  const handleEdit = async () => {
    console.log('Inn handleEdit Func', clientName, gaCode, channelId)
    if (!clientName || !gaCode || !channelId) {
      setErrMsg("Clientname GaCode and ChannelId are required.");
      setShowErrorAlert(true);
      setDialogOpen(false);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 5000);
      return;
    }
    const originalRecord = data.find((c) => c._id === id);
    console.log('originalRecord', originalRecord)
    if (originalRecord && originalRecord.client !== clientName) {
      setErrMsg("Client name cannot be changed.");
      setShowErrorAlert(true);
      setDialogOpen(false);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
      return;
    }

    if (data.some((c) => c.client === clientName && c._id !== id)) {
      setErrMsg("Client name already assigned to another client.");
      setDialogOpen(false);
      setShowErrorAlert(true);
      setDialogOpen(false);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
      return;
    }

    if (data.some((c) => c.ga === gaCode && c._id !== id)) {
      setErrMsg("Ga COde already assigned to another client.");
      setDialogOpen(false);
      setShowErrorAlert(true);

      setDialogOpen(false);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
      return;
    }

    if (data.some((c) => c.id === channelId && c._id !== id)) {
      setErrMsg("Channel Id already assigned to another client.");
      setDialogOpen(false);
      setShowErrorAlert(true);

      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
      return;
    }

    setLoading(true);

    try {
      await EditQuizFields();
      await deactivateClient(clientName, status);
      // await updateDomain();
      await fetchData();

      setGaSwitchVal(false);
      setCompany("");
      setClientName("");
      setGaCode("");
      setChannelId("");
      setClarity("");
      setId("");
      setStatus("");
      setIsEditMode(false);

      setShowEditAlert(true);
      // setSuccess(true);
      setTimeout(() => {
        setShowEditAlert(false);
      }, 2000);

      setDialogOpen(false);
    } catch (error) {
      console.error("Error handling edit:", error);
      setShowEditAlert(false);
      // setSuccess(false);
      setErrMsg(error);
      setShowDeleteAlert(true);
      setTimeout(() => {
        setShowDeleteAlert(false);
        setErrMsg("");
      }, 2000);
    } finally {
      setLoading(false);

    }
  };


  const handleSave = async () => {
    console.log('handle save func run...');
    if (!validateDomain()) {
      closeForm();
      return;
    }
    console.log('clientName', clientName, 'gaCode', gaCode, 'channelId', channelId, 'in handleSave')
    if (!clientName || !channelId || !gaCode) {
      closeForm();
      setErrMsg(
        "Please fill in ALL required fields (Client Name, Channel Id, and GA Code)."
      );
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
      return;
    }
    setLoading(true);
    try {
      console.log('try block run')
      await updateQuizDomain();
      await updateQuizDetails();
      setGagenerated(true);
    } catch (e) {
      console.log('catch block run')
      console.error(e);
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
    } finally {
      console.log('finally block run')
      setLoading(false);
      closeForm();
      setClientVal(clientVal);
      setOpenUrlD(true);
      sendDiscordNotification("A New Domain has been added -  " + clientVal);
    }
    setShowSaveAlert(true);
    setClientName("");
    setGaCode("");
    setChannelId("");
    setClarity("");
    setGaSwitchVal(false);
    setGagenerated(false);
    setSuccess(false);
    await fetchData();
    setTimeout(() => {
      setShowSaveAlert(false);
    }, 2000);
  };

  const handleCopy = () => {
    let url = ref.current.innerText;
    navigator.clipboard.writeText(url);
    setShowCopyAlert(true);
    setTimeout(() => {
      setShowCopyAlert(false);
      closeUrlD();
    }, 1000);
  };
  const handleGa = async () => {
    if (clientVal) {
      setLoading(true);
      try {
        let headersList = {
          "Content-Type": "application/json",
        };

        let bodyContent = JSON.stringify({
          name: clientVal + "(quiztwiz)",
          url: `https//${clientVal}.quiztwiz.com`,
          productType: "QuizTwiz",
        });

        let reqOptions = {
          url: process.env.REACT_APP_API_URL + "ga/generate",
          method: "POST",
          headers: headersList,
          data: bodyContent,
        };

        let response = await axios.request(reqOptions);
        console.log("GA(QuizTwiz)- ", response.data.id);
        setGaCode(response.data.id);
        setSuccess(true);
        setGagenerated(true);
      } catch (error) {
        console.error("Error generating GA:", error);
        alert("Error generating GA:");
      }
      finally {
        setLoading(false);
      }
    } else {
      alert("Please Enter Client First");
    }
  };
  const handleSaveClarity = async () => {
    try {
      let reqOptions = {
        url: process.env.REACT_APP_API_URL + "apiquiztwiz/saveClarity",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: { client: clientName, clarity: clarity },
      };

      let response = await axios.request(reqOptions);

    } catch (e) {
      console.error("Error saving clarity:", e.message);
      setErrMsg(e.message);
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
    }
    setClarity("");
    setOpenClarityModal(false);
    await fetchData();
  };

  const handleSaveCompany = async () => {
    try {
      let reqOptions = {
        url: process.env.REACT_APP_API_URL + "apiquiztwiz/saveCompany",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: { client: clientName, company: company },
      };

      let response = await axios.request(reqOptions);

    } catch (e) {
      console.error("Error saving company:", e.message);
      setErrMsg(e.message);
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 2000);
    }
    setCompany("");
    setOpenCompanyModal(false);
    await fetchData();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = data
    ? data.slice().sort((a, b) => a.client.localeCompare(b.client))
    : [];

  const sortedAndFilteredData = sortedData.filter(
    ({ client, id, ga, status }) =>
      client.toLowerCase().includes(searchInput.toLowerCase()) ||
      id.toLowerCase().includes(searchInput.toLowerCase()) ||
      ga.toLowerCase().includes(searchInput.toLowerCase()) ||
      status.toLowerCase().includes(searchInput.toLowerCase())
  );

  const slicedData = sortedAndFilteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleClientChange = (e) => {
    const clientNameValue = e.target.value;
    const formattedClientName = clientNameValue
      .replace(/\s/g, "")
      .toLowerCase();

    setClientName(formattedClientName);
    setClientVal(formattedClientName);
  };

  const handleCompanyChange = (e) => {

    setCompany(e.target.value);
  };

  const handleGaChange = (e) => {

    setGaCode(e.target.value);
  };
  const handleGaSwitchChange = (e) => {

    setGaSwitchVal(e.target.checked);
  };
  const handleChannelIdChange = (e) => {

    setChannelId(e.target.value);
  };
  const handleClarityChange = (e) => {

    setClarity(e.target.value);
  };
  const widthStyle = { width: "200px" };

  return (
    <div>
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>Create your Qwiztwiz Domain here</h3>
        <Button variant="contained" onClick={showDeletedLogs}>
          Deleted Domain
        </Button>
        <Button variant="contained" onClick={openForm}>
          Add New Domain
        </Button>

        {/* dialog for deleted logs */}
        <Dialog open={deletedLogsOpen} onClose={handleCloseDeletedLogs} fullWidth>
          <DialogTitle>Deleted Domain Details</DialogTitle>
          <DialogContent>
            {deletedLogs ? (
              deletedLogs.length > 0 ? (
                deletedLogs.map((log, index) => (
                  <div key={index}>
                    <p style={{ paddingRight: '5px', fontWeight: 'bold' }}>Client {index + 1}:</p>
                    <p>
                      <span style={{ paddingRight: '5px', fontWeight: 'bold' }}>User ID:</span> {log.userId}
                    </p>
                    <p>
                      <span style={{ paddingRight: '5px', fontWeight: 'bold' }}>Client Name:</span> {log.clientName}
                    </p>
                    <p>
                      <span style={{ paddingRight: '5px', fontWeight: 'bold' }}>Date:</span> {log.formattedTimestamp}
                    </p>
                    {index < deletedLogs.length - 1 && <hr />}
                  </div>
                ))
              ) : (
                <p>No deleted logs found.</p>
              )
            ) : (
              <p>Loading deleted logs...</p>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeletedLogs}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* handlelogs */}
        <Dialog open={logsOpen} onClose={handleCloseLogs} fullWidth>
          <DialogTitle>Previous Quiz Details</DialogTitle>
          <DialogContent>
            {dialogContent ? (
              dialogContent.length > 0 ? (
                dialogContent.map((log, index) => (
                  <div key={index}>
                    <p style={{ paddingRight: "5px", fontWeight: "bold" }}>Change {index + 1}:</p>
                    {log.changes && log.changes.map((change, idx) => (
                      <div key={idx}>
                        <p>
                          <span style={{ paddingRight: "5px", fontWeight: "bold" }}>{change.field.charAt(0).toUpperCase() + change.field.slice(1)}:</span>
                        </p>
                        <p>
                          <span style={{ paddingRight: "5px", fontWeight: "bold" }}>Old Value:</span> {change.oldValue}
                          {/* </p>
                    <p> */}
                          <span style={{ paddingRight: "5px", fontWeight: "bold" }}> New Value:</span> {change.newValue}
                        </p>
                      </div>
                    ))}
                    <p><span style={{ paddingRight: "5px", fontWeight: "bold" }}>Time:</span>{log.time}</p>
                    <p><span style={{ paddingRight: "5px", fontWeight: "bold" }}>User:</span>{log.user}</p>
                    <hr />
                  </div>
                ))
              ) : (
                <p>No logs available.</p>
              )
            ) : (
              <p>Loading logs...</p>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseLogs} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <TextField
          label="Search"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          margin="normal"
          type="search"
        />
      </span>
      <div>
        {showCopyAlert ? (
          <span style={{ position: "fixed", width: "40%", margin: "50px" }}>
            <Alert variant="filled" severity="success" sx={{ mb: 2 }}>
              Copied!
            </Alert>
          </span>
        ) : showSaveAlert ? (
          <span style={{ position: "fixed", width: "40%", margin: "50px" }}>
            <Alert variant="filled" severity="success" sx={{ mb: 2 }}>
              Saved Successfully!
            </Alert>
          </span>
        ) : showDeleteAlert ? (
          <span style={{ position: "fixed", width: "40%", margin: "50px" }}>
            {status === "active" ? (
              <Alert variant="filled" severity="success" sx={{ mb: 2 }}>
                Client {clientVal} Activated Successfully!
              </Alert>
            ) : (
              <Alert variant="filled" severity="warning" sx={{ mb: 2 }}>
                Client {clientVal} Deactivated Successfully!
              </Alert>
            )}
          </span>
        ) : showErrorAlert ? (
          <span style={{ position: "fixed", width: "40%", margin: "50px" }}>
            <Alert variant="filled" severity="error" sx={{ mb: 2 }}>
              {errMsg}
            </Alert>
          </span>
        ) : (
          <></>
        )}
        {showErrorAlert && (
          <div className="alert alert-danger">
            {errMsg}
          </div>
        )}

        {showEditAlert &&
          (<span style={{ position: "fixed", width: "40%", margin: "50px" }}>
            <Alert variant="filled" severity="success" sx={{ mb: 2 }}>
              Edit Successfully !
            </Alert>
          </span>)

        }

        {showDelete && (
          <span style={{ position: "fixed", width: "40%", margin: "50px" }}>

            <Alert variant="filled" severity="success" sx={{ mb: 2 }}>
              Delete Successfully !
            </Alert>
          </span>
        )}


        {/* Dialog for adding more information */}
        <Dialog open={dialogOpen} onClose={closeForm}>
          <DialogTitle>Add More Information</DialogTitle>
          <DialogContent>
            <TextField
              label="Company Name"
              value={company}
              onChange={handleCompanyChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Client Name"
              required
              value={clientName}
              onChange={handleClientChange}
              fullWidth
              margin="normal"
            />
            {!gaSwitchVal ? (
              !gagenerated ? (
                <Button variant="contained" onClick={handleGa}>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : success ? (
                    "Generated Successfully!"
                  ) : (
                    "Generate Your GA Code Here"
                  )}
                </Button>
              ) : (
                <TextField
                  label="GA Code"
                  required
                  value={gaCode}
                  onChange={(e) => { handleGaChange(e) }}
                  margin="normal"
                />
              )
            ) : (
              <TextField
                label="GA Code"
                required
                value={gaCode}
                onChange={(e) => { handleGaChange(e) }}
                margin="normal"
              />
            )}
            <Typography variant="body1">Enter GA manually</Typography>
            <Switch
              checked={gaSwitchVal}
              onChange={handleGaSwitchChange}
              color="primary"
            />
            <TextField
              value={channelId}
              label="Channel Id"
              required
              onChange={handleChannelIdChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Clarity"
              value={clarity}
              onChange={handleClarityChange}
              fullWidth
              margin="normal"
            />
            {isEditMode && (
              <FormControl fullWidth margin="normal">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                  value={status}
                  onChange={handleStatusChange}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={closeForm}>
              Cancel
            </Button>
            {isEditMode ? (
              <Button disabled={loading} onClick={handleEdit}>
                {loading ? <CircularProgress size={24} /> : "Edit"}
              </Button>
            ) : (
              <Button disabled={loading} onClick={handleSave}>
                {loading ? <CircularProgress size={24} /> : "Save"}
              </Button>
            )}
          </DialogActions>
        </Dialog>

        {/* Dialog to show url */}
        <Dialog open={openUrlD} onClose={closeUrlD}>
          <DialogContent>
            <DialogContentText
              sx={{
                borderRadius: "10px",
                padding: "30px",
              }}
            >
              <code ref={ref}>{`https://${clientVal}.quiztwiz.com`}</code>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={closeUrlD}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleCopy}>
              Copy
            </Button>
          </DialogActions>
        </Dialog>
        {/* <Dialog open={showConfirmationDialog} onClose={closeConfirmationDialog}>
          <DialogTitle>
            <b style={{ textTransform: "uppercase" }}>{clientVal}</b>: Are you
            sure you want to change status to{" "}
            <b
              style={{
                fontFamily: "Roboto",
                color: "#325d89",
                textTransform: "uppercase",
              }}
            >
              {status}
            </b>{" "}
          </DialogTitle>
          <DialogContent>
            <Button
              onClick={() => {
                deactivateUrl(clientName, status);
              }}
            >
              Yes
            </Button>
            <Button onClick={closeConfirmationDialog}>No</Button>
          </DialogContent>
        </Dialog> */}

        {/* handleDelete */}
        <Dialog open={showDeleteDialog} onClose={closeDeleteDialog}>
          <DialogTitle>
            Are you
            sure you want to Delete: <b style={{ textTransform: "uppercase" }}>{clientVal}</b>
          </DialogTitle>
          <DialogContent>
            <Button disabled={loading} onClick={() => handleDelete(idToDelete, clientVal)}>
              {loading ? <CircularProgress size={24} /> : "Yes"}
            </Button>
            {/* <Button onClick={() => handleDelete(idToDelete, clientVal)}>
              Yes
            </Button> */}
            <Button onClick={closeDeleteDialog}>No</Button>
          </DialogContent>
        </Dialog>

        <Modal
          open={openClarityModal}
          onClose={() => setOpenClarityModal(false)}
          onSave={handleSaveClarity}
          label="Enter Clarity"
          value={clarity}
          onChange={(e) => setClarity(e.target.value)}
        />
        <Modal
          open={openCompanyModal}
          onClose={() => setOpenCompanyModal(false)}
          onSave={handleSaveCompany}
          label="Enter Company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
      </div>
      <div>
        {data && (
          <>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={widthStyle}>Company</TableCell>
                    <TableCell style={widthStyle}>Client</TableCell>
                    <TableCell style={widthStyle}>ID</TableCell>
                    <TableCell style={widthStyle}>GA Code</TableCell>
                    <TableCell style={widthStyle}>Clarity</TableCell>
                    <TableCell style={widthStyle}>Quiz Domain</TableCell>
                    <TableCell style={widthStyle}>Status</TableCell>
                    <TableCell style={widthStyle}>Edit</TableCell>
                    <TableCell style={widthStyle}>Delete</TableCell>
                    <TableCell style={widthStyle}>Change Logs</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData.map(
                    ({ _id, company, client, id, ga, clarity, status }) => (
                      <TableRow key={company}>
                        {/* <TableCell style={widthStyle}>
                          {company ? (
                            company
                          ) : (
                            <Button
                              onClick={() => {
                                setOpenCompanyModal(true);
                                setClientName(client);
                              }}
                            >
                              Add Company
                            </Button>
                          )}
                        </TableCell> */}
                        <TableCell style={widthStyle}>{company}</TableCell>
                        <TableCell style={widthStyle}>{client}</TableCell>
                        <TableCell style={widthStyle}>{id}</TableCell>
                        <TableCell style={widthStyle}>{ga}</TableCell>
                        <TableCell style={widthStyle}>{clarity}</TableCell>
                        {/* <TableCell style={widthStyle}>
                          {clarity ? (
                            clarity
                          ) : (
                            <Button
                              onClick={() => {
                                setOpenClarityModal(true);
                                setClientName(client);
                              }}
                            >
                              Add Clarity
                            </Button>
                          )}
                        </TableCell> */}
                        <TableCell style={widthStyle}>
                          <Button
                            variant="contained"
                            color="info"
                            onClick={() => openUrl(client)}
                          >
                            URL
                          </Button>
                        </TableCell>
                        {/* <TableCell><DeleteIcon sx={{ color: red[600] }} onClick={() => {
                      setShowConfirmationDialog(true);
                      setClientVal(client);
                    }} /></TableCell> */}
                        <TableCell>
                          {/* <Select
                            value={status}
                            onChange={(event) => {
                              setClientVal(client);
                              setClientName(client);
                              handleStatusChange(clientVal, event.target.value);
                              setShowConfirmationDialog(true);
                            }}
                          >
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="inactive">Inactive</MenuItem>
                          </Select> */}
                          <div style={{ color: status === "active" ? "green" : "orange", fontWeight: "bold" }}>{status}</div>
                        </TableCell>

                        <TableCell style={widthStyle}>
                          {
                            _id ? <Button style={{ color: "blue" }} onClick={() => openEditForm(_id)}>
                              <EditIcon />
                            </Button> : null
                          }
                        </TableCell>
                        {/* handleDelete */}
                        <TableCell >
                          {
                            _id ? <Button style={{ color: "red" }} onClick={() => {
                              setShowDeleteDialog(true);
                              setClientVal(client);
                              setIdToDelete(_id);
                            }}>
                              <DeleteIcon />
                            </Button> : null
                          }
                        </TableCell>
                        {/* handlelogs */}
                        <TableCell >
                          {
                            _id ? <Button onClick={() => handleOpenLogs(_id)}>
                              <InfoIcon />
                            </Button> : null
                          }
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={sortedAndFilteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                size={"small"}
              />
            </Paper>
          </>
        )}
      </div>
    </div>
  );
}
